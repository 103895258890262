.house {
    display: flex;
    justify-content: center;
    margin: 0 24px;
    overflow: scroll;
    height: 100vh;
}

.house > div {
    flex: 1;
}

.house .statistics {
    font-size: 16px;
    margin-top: 12px;
    color: #999;
}

.house-list .house-card {
    width: 100%;
    display: flex;
    padding: 35px 0;
}

.house-list .house-card:not(.last) {
    border-bottom: rgba(0, 0, 0, 0.2) solid 0.5px;
}

.house-list .house-card .left {
    width: 12%;
    min-width: 100px;
}

.house-list .house-card .left img {
    width: 100%;
}

.house-list .house-card .middle {
    margin-left: 24px;
    flex: 1;
}

.house-list .house-card .middle .title {
    color: #394043;
    font-size: 22px;
    font-weight: 600;
}

.house-detail-container {
    display: flex;
    justify-content: center;
}

.house-detail {
    width: 100%;
    max-width: 800px;
}

.house-detail .carousel.fullscreen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
}

.house-detail .carousel .carousel-cell {
    position: relative;
}

.house-detail .carousel .carousel-cell p {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    position: absolute;
    width: 10%;
    bottom: 0;
    margin: 0;
}

.house-detail .carousel .carousel-cell img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    object-position: center center;
}

.house-detail .carousel.fullscreen .carousel-cell img {
    max-height: 100vh;
    object-fit: contain;
}

.house-detail .content {
    margin: 0 24px;
}

.house-detail .content .title {
    color: #394043;
    font-size: 22px;
    font-weight: 600;
}

.house-detail .back-button {
    position: fixed;
    left: 12px;
    top: 12px;
    z-index: 100;
}

.house-detail .content .buttons {
    margin-top: 12px;
}

.house-detail .content .buttons > button {
    margin-right: 12px;
}

.house-detail .content h3 {
    margin-top: 24px;
    padding-top: 12px;
    border-top: rgba(0, 0, 0, 0.2) solid 0.5px;
}

.house-detail .content img.rooms {
    width: 100%;
    height: 600px;
    object-fit: contain;
}