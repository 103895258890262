.introduction {
    background: white;
    font-family: "Courier";
}
.introduction p {
    text-align: center;
    padding-bottom: 48px;
    font-size: 18px;
}
.introduction h1 {
    text-align: center;
    font-size: 48px;
    padding-top: 10px;
}
.header-image {
    width: 60%;
    margin-left: 20%;
}