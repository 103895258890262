.house-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.house-info .info-container .detail {
    color: #616669
}

.house-info .info-container > div {
    margin-top: 12px;
}

.house-info .info-container > div .anticon {
    margin-right: 8px;
}

.house-info .price-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.house-info .price-container .total_price {
    color: #db4c3f;
}

.house-info .price-container .total_price .number {
    font-size: 26px;
    font-weight: 600;
}

.house-info .price-containers .single_price {
    color: #394043;
}