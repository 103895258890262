.filter-panel .filter {
    margin-top: 24px;
}

.filter-panel .filter .ant-col {
    margin-bottom: 12px;
}

.filter-panel .filter .form-item-label {
    display: inline-block;
    width: 120px;
    text-align: right;
}